<template>
  <div class="main">
    <div v-if="true" class="calendar-container text-center">
      <div v-if="showSearchDlg || healthyCenterInfo" class="mask" @click="cancelDig()"></div>
      <!-- 彈跳視窗 1 -->
      <div v-if="showSearchDlg" class="popup-dlg  abs-center">
        <div class="popup-dlg-search bound-margin">
          <div class="clr-fnot-color balance-bg-color   fl-between ">
            <span>{{ `${date}  可見習的衛生所資訊` }}</span>
            <div @click="cancelDig()" style="font-size:28px " class="pointer">
              <span class="fas fa-times-circle"></span>
            </div>
          </div>
          <div class="inline-3-n clr-bg-color  ">
            <div class="on-hover">
              <div v-for="(center, n) in dateActivities" :key="n" @click="onClickCenterList(center)" class="pointer">{{ center.Name }}</div>
            </div>
          </div>
          <div style="text-align: left;" v-if="healthyCenterInfo">
            <span style="vertical-align: middle;" class="  clr-fnot-color">衛生所資訊</span>
          </div>
          <div class="clr-bg-color ">
            <div v-if="healthyCenterInfo" class="grid-info-2-10">
              <div>
                <span>{{ `名稱 :${healthyCenterInfo.Name}` }}</span>
              </div>
              <div>
                <span>{{ `見習類別 :${healthyCenterInfo.TCategory}` }}</span>
              </div>
              <div>
                <span>{{ `簡稱 :${healthyCenterInfo.ShortName}` }}</span>
              </div>
              <div>
                <span>{{ `電子郵件 :${healthyCenterInfo.Email}` }}</span>
              </div>
              <div>
                <span>{{ `電話 :${healthyCenterInfo.Phone}` }}</span>
              </div>
              <div>
                <span>{{ `地址 :${healthyCenterInfo.Address}` }}</span>
              </div>
              <div class="g-b-fax">
                <span>{{ `傳真 :${healthyCenterInfo.Fax}` }}</span>
              </div>
              <div class="g-b-remarks">
                <span>{{ `其它事項業務 :${healthyCenterInfo.Remark}` }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 彈跳視窗 2 -->
      <div v-if="showSearchInfoDlg" class="popup-dlg   abs-center">
        <div class="popup-dlg-info bound-margin">
          <div class="clr-fnot-color balance-bg-color   fl-between ">
            <span class=" ">衛生所資訊</span>
            <div @click="cancelDig()" style="font-size:28px " class="pointer">
              <span class="fas fa-times-circle"></span>
            </div>
          </div>
          <div class="clr-bg-color">
            <div v-if="healthyCenterInfo" class="grid-info-2-10">
              <div>
                <span>{{ `名稱 :${healthyCenterInfo.Name}` }}</span>
              </div>
              <div>
                <span>{{ `見習類別 :${healthyCenterInfo.TCategory}` }}</span>
              </div>
              <div>
                <span>{{ `簡稱 :${healthyCenterInfo.ShortName}` }}</span>
              </div>
              <div>
                <span>{{ `電子郵件 :${healthyCenterInfo.Email}` }}</span>
              </div>
              <div>
                <span>{{ `電話 :${healthyCenterInfo.Phone}` }}</span>
              </div>
              <div>
                <span>{{ `地址 :${healthyCenterInfo.Address}` }}</span>
              </div>
              <div class="g-b-fax">
                <span>{{ `傳真 :${healthyCenterInfo.Fax}` }}</span>
              </div>
              <div class="g-b-remarks">
                <span>{{ `其它事項業務 :${healthyCenterInfo.Remark}` }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 主視窗 -->
      <v-calendar ref="calendar" v-if="true" locale="zh-TW" :masks="masks" class="custom-calendar" :attributes="attributes" disable-page-swipe is-expanded>
        <template v-slot:header="{ month, year }">
          <div class="header-height balance-bg-color clr-fnot-color inline-5">
            <div @click="moveFormMonth('prev_y', year, month)">{{ "<<" }}</div>
            <div @click="moveFormMonth('prev_m', year, month)">{{ "<" }}</div>
            <div>{{ `${year} ${month}月 ` }}</div>
            <div @click="moveFormMonth('next_m', year, month)">{{ ">" }}</div>
            <div @click="moveFormMonth('next_y', year, month)">{{ ">>" }}</div>
          </div>
        </template>
        <template v-slot:header-left-button>
          <span style="width:0px;"></span>
        </template>
        <template v-slot:header-right-button>
          <span style="width:0px;"></span>
        </template>
        <template v-slot:day-content="{ day, attributes }">
          <div style="text-align: left;background-color: #D5DAF477; ">
            <span class="bound-margin" @click="showCenterMore(day)">{{ day.day }}</span>
          </div>
          <div v-if="attributes && attributes.length > 5">
            <div class="list-color">
              <div class="day-content" v-for="(attribute, index) in attributes.slice(0, 5)" :key="index" @click="showCenterInfo(attribute)">
                <span>{{ attribute.customData.title }}</span>
              </div>
              <div class="day-content pointer">
                <span style="width: 135px;display: inline-block; height:20px;" @click="showCenterMore(day)">更多...</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="list-color">
              <div class="day-content" v-for="(attribute, index) in attributes" :key="index" @click="showCenterInfo(attribute)">
                <span>{{ attribute.customData.title }}</span>
              </div>
            </div>
          </div>
        </template>
      </v-calendar>
    </div>
  </div>
</template>

<script>
import moment from "moment";
const weekday = {
  "1": "日",
  "2": "一",
  "3": "二",
  "4": "三",
  "5": "四",
  "6": "五",
  "7": "六",
};
export default {
  data() {
    return {
      showSearchInfoDlg: false,
      showSearchDlg: false,
      date: "",
      dateActivities: [],
      activities: [],
      healthyCenterInfo: null,
      tableData: [],
      masks: {
        weekdays: "WWWW",
      },
      attributes: [],
    };
  },
  async mounted() {
    await this.goYearMonth(moment().format("YYYY"), moment().format("MM"));
  },
  methods: {
    async getTrainingPlan(start, end) {
      return await window.axios.get(`Training/GetHCTraining?startdate=${start}&enddate=${end}`);
    },
    cancelDig() {
      this.showSearchInfoDlg = false;
      this.showSearchDlg = false;
      this.healthyCenterInfo = null;
    },
    showCenterMore(date) {
      this.date = `${date.year}年 ${date.month.toString().padStart(2, "0")}月 ${date.day.toString().padStart(2, "0")}日 星期${weekday[date.weekday]}`;
      this.dateActivities = this.activities.filter((s) => {
        return s.TDate.includes(`${date.year}-${date.month.toString().padStart(2, "0")}-${date.day.toString().padStart(2, "0")}`);
      });

      if (!this.dateActivities.length) return;

      this.healthyCenterInfo = null;
      this.showSearchDlg = true;
      this.showSearchInfoDlg = false;
    },
    showCenterInfo(info) {
      let dist = info.customData;
      let center = this.activities.find((s) => s.Code === dist.id);
      this.healthyCenterInfo = center;
      this.showSearchInfoDlg = true;
      this.showSearchDlg = false;
    },
    async goYearMonth(y, m) {
      let to = `${this.getFomDateMonth("next_m", y, m).year}-${this.getFomDateMonth("next_m", y, m).month}`;
      let activitis = await this.getTrainingPlan(moment(`${y}-${m}`).format("YYYY-MM-DD"), moment(to).format("YYYY-MM-DD"));
      this.updateActivitys(activitis);
      await this.$refs.calendar.move(`${y}-${m}`);
    },
    getFomDateMonth(goWhen, fromY, fromM) {
      let y = parseInt(fromY);
      let m = parseInt(fromM);
      switch (goWhen) {
        case "prev_y":
          y = y - 1;
          break;
        case "prev_m":
          if (m === 1) {
            y = y - 1;
            m = 12;
          } else {
            m = m - 1;
          }
          break;
        case "next_m":
          if (m === 12) {
            y = y + 1;
            m = 1;
          } else {
            m = m + 1;
          }
          break;
        case "next_y":
          y = y + 1;
          break;
      }
      return { year: y, month: m };
    },
    async moveFormMonth(goWhen, fromY, fromM) {
      let month = this.getFomDateMonth(goWhen, fromY, fromM);
      await this.goYearMonth(month.year, month.month);
    },
    async updateActivitys(activities) {
      this.activities = activities;
      activities.forEach((s) => {
        let record = {
          key: s.Code,
          customData: {
            id: s.Code,
            title: s.Name,
          },
          dates: s.TDate,
        };
        this.attributes.push(record);
      });
    },
    onClickCenterList(center) {
      this.healthyCenterInfo = center;
    },
  },
};
</script>
// pubup
<style lang="scss" scoped>
$ui-balance-bg: #428bca;
$ui-clr-bg: white;

.main {
  width: 100%;
  overflow-x: auto;
}
.mask {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #00000022;
  z-index: 2;
}
.on-hover {
  padding: 5px;
  :hover {
    background-color: $ui-balance-bg;
    color: white;
  }
}
.pointer {
  cursor: pointer;
}
.test {
  background-color: yellow;
}
.balance-bg-color {
  background-color: $ui-balance-bg;
}
.clr-bg-color {
  background-color: $ui-clr-bg;
}
.clr-fnot-color {
  color: $ui-clr-bg;
}

.bound-margin {
  margin: 8px;
}
.abs-center {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.calendar-container {
  padding: 30px;
  position: relative;
}
.popup-dlg-search {
  grid-template-rows: 60px auto 30px 210px;
  > div {
    border: 0px solid red;
  }
}
.popup-dlg-info {
  grid-template-rows: 60px 200px;
}
.popup-dlg {
  background-color: $ui-balance-bg;
  position: fixed;
  max-width: 650px;
  width: 60%;
  z-index: 3;
  display: grid;
  border-radius: 10px;
}
.grid-info-2-10 {
  margin: 10px;
  > div {
    margin: 5px;
    text-align: left;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.inline-3-n {
  min-height: 160px;
  > div {
    text-align: left;
    overflow: auto;
    height: 100%;
    > div {
      box-sizing: border-box;
      width: 33.3333%;
      display: inline-block;
    }
  }
}
.list-color {
  > div {
    height: 25px;
    margin-top: 3px;
    > span {
      padding: 2px 9px;
      line-height: 1.2rem;
      background-color: #1e93a255;
      border-radius: 5px;
      width: 160px;
      cursor: pointer;
    }
  }
  > div:nth-child(odd) {
    > span {
      background-color: #e9f5f6;
    }
  }
}

.fl-between {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.g-b-remarks,
.g-b-fax {
  grid-column-start: 1;
  grid-column-end: 3;
}
</style>

// calendar
<style lang="scss">
$calendar-bg-color: #2680eb33;
$day-content-height: 190px;
$calendar-header-bg-color: #f1f5f8;
$calendar-border: 1px solid #00000033;
.header-height {
  height: 45px;
}
.day-content {
  color: #242222;
  font-weight: 400;
  font-size: 0.8rem;
}

.custom-calendar {
  font-size: 16px;
  background-color: $calendar-bg-color;
  & .vc-header {
    background-color: $calendar-header-bg-color;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: white;
    color: black;
    border-bottom: $calendar-border;
    border-top: $calendar-border;
    padding: 5px 0;
  }

  & .vc-day {
    height: $day-content-height;

    margin: 4px 2px;
    background-color: white;
    border-radius: 3px;
    &:not(.on-bottom) {
      border-bottom: $calendar-border;
      &.weekday-1 {
        border-bottom: $calendar-border;
      }
    }
    &:not(.on-right) {
      border-right: $calendar-border;
    }
  }
}
.inline-5 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    cursor: pointer;
    height: 38px;
    border: 1px solid #ffffffee;
    padding: 3px 8px;
  }
}
</style>
